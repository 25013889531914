// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Input, Button, FormControl, FormHelperText } from '@mui/joy';
import styles from './Login.module.css';
import CodeInput from './CodeInput';

const Login = () => {
  const { signUp, verifyCode } = useAuth();
  const { currentUser } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (currentUser && currentUser.token) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const validateInput = () => {
    let isValid = true;
    if (!email.includes('@')) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    }
    if (!firstName.trim()) {
      setFirstNameError('First name is required.');
      isValid = false;
    }
    if (!lastName.trim()) {
      setLastNameError('Last name is required.');
      isValid = false;
    }
    if (!phoneNumber.trim() || phoneNumber.length < 10) {
      setPhoneError('Please enter a valid phone number with at least 10 digits.');
      isValid = false;
    }
    return isValid;
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    // Clear previous errors
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setPhoneError('');

    if (validateInput()) {
        console.log({ email, firstName, lastName, phoneNumber })
        const isSignUpValid = await signUp(firstName, lastName, email, phoneNumber);
        if (isSignUpValid) {
            setEmailSent(true);
        }
    }
  };

  const handleCodeSubmit = async (event) => {
    const isAuthenticated = await verifyCode(email, event);
    if (isAuthenticated) {
      navigate('/dashboard'); // Navigate to the dashboard upon successful authentication.
    }
  };

  return (
    <div className={styles.banner}>
      <div className={styles.header}>
        <h1>KinAlma</h1>
        <p>Lets work together</p>
      </div>
      <div className={styles.card}>
        <form onSubmit={emailSent ? handleCodeSubmit : handleSignUp}>
        {!emailSent ? (
            <>
          <FormControl error={!!firstNameError}>
          {/* <FormLabel>First Name</FormLabel> */}
            <Input
              placeholder="first name"
              variant="soft"
              value={firstName}
              color={'white'}
              sx={{
                '--Input-focusedHighlight': 'rgb(37 139 240) !important', // Setting focus highlight color locally
              }}
              onChange={e => setFirstName(e.target.value)}
            />
            {firstNameError && <FormHelperText>{firstNameError}</FormHelperText>}
          </FormControl>

          <FormControl error={!!lastNameError}>
            <Input
              placeholder="last name"
              variant="soft"
              value={lastName}
              color={'white'}
              sx={{
                '--Input-focusedHighlight': 'rgb(37 139 240) !important', // Setting focus highlight color locally
              }}
              onChange={e => setLastName(e.target.value)}
            />
            {lastNameError && <FormHelperText>{lastNameError}</FormHelperText>}
          </FormControl>

          <FormControl error={!!emailError}>
            <Input
              placeholder="email"
              variant="soft"
              value={email}
              color={'white'}
              sx={{
                '--Input-focusedHighlight': 'rgb(37 139 240) !important', // Setting focus highlight color locally
              }}
              onChange={e => setEmail(e.target.value)}
            />
            {emailError && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>

          <FormControl error={!!phoneError}>
            <Input
              placeholder="phone"
              variant="soft"
              value={phoneNumber}
              color={'white'}
              sx={{
                '--Input-focusedHighlight': 'rgb(37 139 240) !important', // Setting focus highlight color locally
              }}
              onChange={e => setPhoneNumber(e.target.value)}
            />
            {phoneError && <FormHelperText>{phoneError}</FormHelperText>}
          </FormControl>

          <button type="submit"  className={styles.login_button} >
            Sign Up
          </button>
          </>
        ) : (
            <>
                <CodeInput numberOfDigits={6} onComplete={handleCodeSubmit}/>
                            {/* <Input name="Soft" placeholder="code" variant="soft" value={code}
                                onChange={(e) => setCode(e.target.value)} slotProps={{ input: { component: TextMaskAdapter } }}/>    */}
                <button className={styles.login_button} type="submit">Login</button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
