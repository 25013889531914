import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './components/Landing'; // Make sure to create this component
import Login from './components/Login';
import Signup from './components/Signup'
import Dashboard from './components/dashboard/Dashboard';
import NotFound from './components/NotFound';
import AuthGuard from './guards/auth.guard';
import Personal from './components/dashboard/Personal'
import './App.css'


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<Signup />} />
      <Route path="/dashboard" element={
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      } />
      {/* Separate route but still secured and logically a child of dashboard */}
      <Route path="/dashboard/personal" element={
        <AuthGuard>
          <Personal />
        </AuthGuard>
      } />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
