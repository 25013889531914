import React, { useState } from "react";
import styles from './Navbar.module.css'

const Navbar = () => {
  const handleNavLinkClick = (e, targetIdFromHref) => {
    e.preventDefault();
    // Use the provided targetIdFromHref if available, otherwise, extract from href
    const targetId = targetIdFromHref || e.currentTarget.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
  
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  
  
  return (
    <div className={styles.nav}>
      <div className={styles.nav_logo_container}>
        <a>KinAlma</a>
      </div>
      <div className={styles.navbar_links_container}>
        <a className={styles.nav_link} onClick={handleNavLinkClick} href="#">Services</a>
        <a className={styles.nav_link} onClick={handleNavLinkClick} href="#">Testimonies</a>
        <a className={styles.nav_link} onClick={handleNavLinkClick} href="#">About</a>
        <a className={styles.nav_link} onClick={handleNavLinkClick} href="#">Contact</a>
        <button onClick={() => (window.location.href = '/login')} className={styles.login_button}>Log In</button>
      </div>
    </div>
  );
};

export default Navbar;
