import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import styles from './Testimonials.module.css'



const Testimonials = () => {
  return (
    <div className={styles.testimonials_wrapper} id="testimonials">
      <div className={styles.testimonials}>
        <h1 className={styles.heading}>Testimonials</h1>
        <p className={styles.text}>
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>
      </div>
      <div className={styles.testimonials_slider}>
        <Swiper className={styles.swiper}
            spaceBetween={50}
            slidesPerView={3}
            // centeredSlides={true}
            loop={true}
            autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
        }}
        modules={[Autoplay]}
        >
         <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.testimonial_slide}>
            
              <p className="testimonial-company">People 1</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.testimonial_slide}>
 
              <p className="testimonial-company">People 2</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.testimonial_slide}>
       
              <p className="testimonial-company">People 3</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.testimonial_slide}>

              <p className="testimonial-company">People 4</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.testimonial_slide}>

              <p className="testimonial-company">People 5</p>
            </div>
          </SwiperSlide>

        </Swiper>
       
      </div>
    </div>
  );
};

export default Testimonials;
