import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import Swal from 'sweetalert2'

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const checkEmail = async (email) => {
    try {
      const response = await fetch('https://kinalma-backend-1363c82b0e04.herokuapp.com/checkEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      console.log(data);
      if (!data['existingUser']) {
        Swal.fire({
          title: 'Error!',
          text: data['message'],
          icon: 'error',
          showConfirmButton: false,
          timer: 2500
        })
      } else {
        Swal.fire({
          title: 'Code Sent!',
          text: 'Check your email!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        })
      }

      return data['existingUser'];
    } catch (error) {
      console.log(error)
      console.error('Email check failed:', error);
      return false;
    }
  };

  const verifyCode = async (email, code) => {
    try {
      const response = await fetch('https://kinalma-backend-1363c82b0e04.herokuapp.com/loginWithCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, loginCode: code }),
      });
      const data = await response.json();
      console.log(data)
      if (!data['correctCode']) {
        Swal.fire({
          title: 'Error!',
          text: data['message'],
          icon: 'error',
          showConfirmButton: false,
          timer: 2500
        })
        return false
      }
      const token = data['token'];
      if (token) {
        localStorage.setItem('token', token);
        const decoded = jwtDecode(token);
        console.log(decoded)
        setCurrentUser({ token, email: decoded.email, firstName: data['firstName'], lastName: data['lastName'] });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Code verification failed:', error);
      return false;
    }
  };

  const checkTokenExpiry = (token) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  const signUp = async (firstName, lastName, email, phone) => {
    try {
      const response = await fetch('https://kinalma-backend-1363c82b0e04.herokuapp.com/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName: firstName, lastName: lastName, email: email, phone: phone }),
      });
      const data = await response.json();
      console.log(data)
      const status = data['status']

      if (status === 200) {
        return true;
      }
      return false

    } catch (error) {
      console.log('Sign up failed: ', error);
      return false
    }

  }

  const logout = () => {
    localStorage.removeItem('token');
    setCurrentUser(null);
  };

  const getUser = async () => {
    try {
      const response = await fetch('https://kinalma-backend-1363c82b0e04.herokuapp.com/getUser', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + currentUser['token'],
        }
      });
      const data = await response.json();
      return data

    } catch (error) {
      console.log('Get User failed: ', error);
      return null
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && checkTokenExpiry(token)) {
      const decoded = jwtDecode(token);
      setCurrentUser({ token, email: decoded.email });
    } else {
      console.log("Token is expired or invalid");
      logout();
    }
  }, []);

  const value = { currentUser, checkEmail, verifyCode, signUp, logout, getUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
