import AOS from 'aos';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import Button from '@mui/joy/Button';
import 'aos/dist/aos.css';
import styles from'./Dashboard.module.css'
import Navbar from '../Navbar';
import { FaPeopleRobbery } from "react-icons/fa6";

const Dashboard = () => {
    const { logout, getUser } = useAuth();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        AOS.init();
        const fetchUserData = async () => {
            const data = await getUser(); // Assuming getUser is asynchronous
            setUserData(data);
        };
        fetchUserData();
    }, []);

    return (
        <div className="App">
            <div className={styles.nav}>
                <div className={styles.nav_logo_container}>
                    <a>KinAlma</a>
                </div>
                <div className={styles.navbar_links_container}>
                    <a onClick={logout}>Log Out</a>
                </div>
            </div>
            <div className={styles.banner}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        {/* <h1 data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">KinAlma</h1> */}
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                            Welcome, {userData ? userData.fullName : 'loading...'}!
                        </p>
                        {/* <Button className="logout" onClick={logout}>Logout</Button> */}
                    </div>
                </div>
                <div className={styles.stages} >
                    <div data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000">
                        <Link to="/dashboard/personal" className={styles.stage_1} >
                            <FaPeopleRobbery size={70}/>
                            <p>Fill out Love one data</p>
                        </Link>   
                    </div>
                
                    <div className={styles.stage_2} data-aos="fade-up" data-aos-delay="1100" data-aos-duration="1000">
                        <p>Send Invites & Kinoke</p>
                    </div>
                </div>
                

            </div>
            <div className={styles.footer}>
                <span>&copy; {new Date().getFullYear()} KinAlma. All rights reserved.</span>
            </div>
        </div>
    );
};

export default Dashboard;
