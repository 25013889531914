import AOS from 'aos';
import React, { useEffect, useState } from 'react';
import { Input, Button, FormControl, FormHelperText, FormLabel } from '@mui/joy';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { submitUserData } from '../../services/DashboardApis'
import styles from'./Personal.module.css'
import { useAuth } from '../../contexts/AuthContext';
import Navbar from '../Navbar';
import Swal from 'sweetalert2'

const Personal = () => {
    const { currentUser, getUser } = useAuth();
    const [userData, setUserData] = useState(null);
    const [savedData, setSavedData] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfPassing, setDateOfPassing] = useState('');
    const [dateOfPassingError, setDateOfPassingError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = await submitUserData(firstName, lastName, dateOfBirth, dateOfPassing, currentUser['token']);
        if (data['status'] === 200) {
          Swal.fire({
            title: 'Information Saved!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500
          });
          setSavedData(true);
          setUserData(data['lovedOneInfo']);
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'There was a problem saving your data.',
            icon: 'error',
            showConfirmButton: true
          });
        }
      };
    
    useEffect(() => {
        const fetchUserData = async () => {
            const data = await getUser();
            if (Object.keys(data["lovedOneInfo"]).length === 0) {
                setSavedData(false);
                setUserData({});
            } else {
                setUserData(data["lovedOneInfo"]);
                setSavedData(true);
            }
        };
        fetchUserData();
    }, [getUser]);

    return (
        <div className="App">
            <div className={styles.nav}>
                <div className={styles.nav_logo_container}>
                    <a>KinAlma</a>
                </div>
                <div className={styles.navbar_links_container}>
                    <Link className={styles.navbar_links_container_link} to="/dashboard">&#8592;</Link>
                </div>
            </div>
            <div className={styles.banner}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        {/* <h1 data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">KinAlma</h1> */}
                        <p data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                            Lets add important information about your loved one. 
                        </p>
                        {/* <Button className="logout" onClick={logout}>Logout</Button> */}
                    </div>
                </div>

                {savedData ? (
                    <div data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000">
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel sx={{ color: 'white', fontSize: '18px'}}>First Name</FormLabel>
                      <p className={styles.savedUserData}>{userData.firstName}</p>
                    </FormControl>
                
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel sx={{ color: 'white', fontSize: '18px'}}>Last Name</FormLabel>
                      <p className={styles.savedUserData}>{userData.lastName}</p>
                    </FormControl>
                
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel sx={{ color: 'white', fontSize: '18px'}}>Date of Birth</FormLabel>
                      <p className={styles.savedUserData}>{userData.dateOfBirth}</p>
                    </FormControl>
                
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel sx={{ color: 'white', fontSize: '18px'}}>Date of Passing</FormLabel>
                      <p className={styles.savedUserData}>{userData.dateOfPassing}</p>
                    </FormControl>
                
                    <button
                      className={styles.login_button}
                      onClick={() => {
                        setFirstName(userData.firstName);
                        setLastName(userData.lastName);
                        setDateOfBirth(userData.dateOfBirth);
                        setDateOfPassing(userData.dateOfPassing);
                        setSavedData(false);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                <form onSubmit={handleSubmit} data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000">
                  <FormControl error={!!firstNameError} sx={{ width: '100%' }}>
                    <FormLabel sx={{color: 'white', fontSize: '17px'}}>First Name</FormLabel>
                    <Input
                      placeholder=""
                      variant="soft"
                      value={firstName}
                      required
                      color={'white'}
                      sx={{
                        '--Input-focusedHighlight': 'rgb(37 139 240) !important',
                        width: '100%'
                      }}
                      onChange={e => setFirstName(e.target.value)}
                    />
                    {firstNameError && <FormHelperText>{firstNameError}</FormHelperText>}
                  </FormControl>

                  <FormControl error={!!lastNameError} sx={{ width: '100%' }}>
                    <FormLabel sx={{color: 'white', fontSize: '17px'}}>Last Name</FormLabel>
                    <Input
                      placeholder=""
                      variant="soft"
                      value={lastName}
                      required
                      color={'white'}
                      sx={{
                        '--Input-focusedHighlight': 'rgb(37 139 240) !important',
                        width: '100%'
                      }}
                      onChange={e => setLastName(e.target.value)}
                    />
                    {lastNameError && <FormHelperText>{lastNameError}</FormHelperText>}
                  </FormControl>

                  <FormControl error={!!dateOfBirthError} sx={{ width: '100%' }}>
                    <FormLabel sx={{color: 'white', fontSize: '17px'}}>Date of Birth</FormLabel>
                    <Input
                      type="date"
                      value={dateOfBirth}
                      required
                      color={'white'}
                      sx={{
                        '--Input-focusedHighlight': 'rgb(37 139 240) !important', 
                        width: '100%' 
                      }}
                      onChange={e => setDateOfBirth(e.target.value)}
                    />
                    {dateOfBirthError && <FormHelperText>{dateOfBirthError}</FormHelperText>}
                  </FormControl>

                  <FormControl error={!!dateOfPassingError} sx={{ width: '100%' }}>
                    <FormLabel sx={{color: 'white', fontSize: '17px'}}>Date of Passing</FormLabel>
                    <Input
                      type="date"
                      variant="soft"
                      value={dateOfPassing}
                      required
                      color={'white'}
                      sx={{
                        '--Input-focusedHighlight': 'rgb(37 139 240) !important',
                        width: '100%'
                      }}
                      onChange={e => setDateOfPassing(e.target.value)}
                    />
                    {dateOfPassingError && <FormHelperText>{dateOfPassingError}</FormHelperText>}
                  </FormControl>

                  <button type="submit" className={styles.login_button}>
                    Save
                  </button>
                </form>
              )}
            </div>
            <div className={styles.footer}>
                <span>&copy; {new Date().getFullYear()} KinAlma. All rights reserved.</span>
            </div>
        </div>
    );
};

export default Personal;
