// src/guards/AuthGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthGuard = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    // Redirect to the login page if there is no current user
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthGuard;
