// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Input from '@mui/joy/Input';
import styles from './Login.module.css'
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import CodeInput from './CodeInput';


const Login = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { checkEmail, verifyCode } = useAuth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.token) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const isEmailValid = await checkEmail(email);
    if (isEmailValid) {
      setEmailSent(true); // Changes the form to the code input form.
    } 
  };

  const handleCodeSubmit = async (event) => {
    const isAuthenticated = await verifyCode(email, event);
    if (isAuthenticated) {
      navigate('/dashboard'); // Navigate to the dashboard upon successful authentication.
    }
  };

  const handleSignUpClick = () => {
    navigate('/signup')
  }


  return (
    <div className={styles.banner}>
      <div className={styles.header}>
          <h1>KinAlma</h1>
          <p>Lets work together</p>
        </div>
      <div>
        <form onSubmit={emailSent ? handleCodeSubmit : handleEmailSubmit}>
          {!emailSent ? (
            <>
              <Input 
                name="Soft"
                placeholder="email"
                variant="soft"
                value={email}
                color={'white'}
                sx={{
                  '--Input-focusedHighlight': 'rgb(37 139 240) !important', // Setting focus highlight color locally
                }}

                onChange={(e) => setEmail(e.target.value)} />   
              <button className={styles.login_button} type="submit">Get Code</button>
              <button className={styles.signup_button} type="button" onClick={handleSignUpClick}>
                Sign Up
            </button>
            </>
          ) : (
            <>
              <CodeInput numberOfDigits={6} onComplete={handleCodeSubmit}/>
              {/* <Input name="Soft" placeholder="code" variant="soft" value={code}
                onChange={(e) => setCode(e.target.value)} slotProps={{ input: { component: TextMaskAdapter } }}/>    */}
              <button className={styles.login_button} type="submit">Login</button>
            </>
          )}
        </form>
      </div>
  </div>
  
  );
};

export default Login;
