import React, { useRef, useEffect, useState } from 'react';

function CodeInput({ numberOfDigits, onComplete }) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  useEffect(() => {
    // Check if all digits are filled and if so, call onComplete
    if (otp.every(digit => digit.trim() !== "")) {
      onComplete(otp.join(""));
    }
  }, [otp, onComplete]); 

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if(value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numberOfDigits - 1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  return (     
     <div className='codeInput'>
      {otp.map((digit, index)=>(
        <input key={index} value={digit} maxLength={1}  
        onChange={(e)=> handleChange(e.target.value, index)}
        onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
        ref={(reference) => (otpBoxReference.current[index] = reference)}
        // className={}
        />
      ))}
     </div>
  );
}

export default CodeInput;