import AOS from 'aos';
import React, {useEffect} from "react";
import 'aos/dist/aos.css';

const NotFound = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
       <div>
        <h1>Not found!</h1>
       </div>
    );
};


export default NotFound;
