import AOS from 'aos';
import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/joy/Button';
import 'aos/dist/aos.css';
import styles from './Landing.module.css'
import Navbar from './Navbar';
import Testimonials from './Testimonials';

const Landing = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    const navigate = useNavigate();
    return(
        <div className="App">
            <Navbar/>
            <div className={styles.banner}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <h1 data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">Lets cherish your loved one together</h1>
                        </div>
                        {/* <Button className="login" onClick={() => (navigate('/dashboard'))}>Login</Button> */}
                    </div>

                    <div className={styles.scrolldown}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                
            </div>
            <Testimonials />
                <div className="footer">
                    <span>&copy; {new Date().getFullYear()} KinAlma. All rights reserved.</span>
                </div>
        </div>
    );
};


export default Landing;
