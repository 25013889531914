


export const submitUserData = async (firstName, lastName, dateOfBirth, dateOfPassing, token) => {
    try {
        const response = await fetch('https://kinalma-backend-1363c82b0e04.herokuapp.com/saveLovedOneInfo', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ firstName: firstName, lastName: lastName, dateOfBirth: dateOfBirth, dateOfPassing: dateOfPassing }),
        });
        const data = await response.json();
        console.log(data);
        return data
  
      } catch (error) {
        console.log('Post user data: ', error);
        return null
      }
}